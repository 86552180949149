import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import NotFound from "@/pages/NotFoundPage.vue";
const Login = () => import("@/pages/Login.vue");
const PasswordReset = () => import("@/pages/PasswordReset.vue");

// Admin pages
const Dashboard = () => import("@/pages/Dashboard.vue");
const Profile = () => import("@/pages/Profile.vue");
const Notifications = () => import("@/pages/Notifications.vue");
const Icons = () => import("@/pages/Icons.vue");
const Typography = () => import("@/pages/Typography.vue");
const TableList = () => import("@/pages/TableList.vue");
const Servisy = () => import("@/pages/Servisy.vue");
const Obhliadky = () => import("@/pages/Obhliadky.vue");
const Klienti = () => import("@/pages/Klienti.vue");
const CenovePonuky = () => import("@/pages/CenovePonuky.vue");
const Objednavky = () => import("@/pages/Objednavky.vue");
const Produkty = () => import("@/pages/Produkty.vue");
const Settings = () => import("@/pages/Settings.vue");

const routes = [
  {
    path: "/home",
    component: DashboardLayout,
    redirect: "/home/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "servisy",
        name: "Servisy",
        component: Servisy,
      },
      {
        path: "obhliadky",
        name: "Obhliadky",
        component: Obhliadky,
      },
      {
        path: "clients",
        name: "Klienti",
        component: Klienti,
      },
      {
        path: "price-offers",
        name: "Cenove ponuky",
        component: CenovePonuky,
      },
      {
        path: "orders",
        name: "Objednavky",
        component: Objednavky,
      },
      {
        path: "products",
        name: "Produkty",
        component: Produkty,
      },
      {
        path: "settings",
        name: "Nastavenia",
        component: Settings,
      },
      {
        path: "icons",
        name: "Icons",
        component: Icons,
      },
      {
        path: "typography",
        name: "Typography",
        component: Typography,
      },
      {
        path: "table-list",
        name: "Table List",
        component: TableList,
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    name: "default",
    redirect: "/login",
    component: Login,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/password-reset",
    name: "Password Reset",
    component: PasswordReset,
  },
  // Catch-all route for 404 pages
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
];

export default routes;
