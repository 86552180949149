<template>
  <div class="notifications">
    <transition-group :name="transitionName" :mode="transitionMode">
      <Notification
        v-for="notification in notifications"
        v-bind="notification"
        :clickHandler="notification.onClick"
        :key="notification.timestamp.getTime()"
        @close="removeNotification"
      />
    </transition-group>
  </div>
</template>

<script>
import { defineComponent, computed, watch, getCurrentInstance } from 'vue';
import Notification from './Notification.vue';

export default defineComponent({
  components: {
    Notification,
  },
  props: {
    transitionName: {
      type: String,
      default: 'list',
    },
    transitionMode: {
      type: String,
      default: 'in-out',
    },
    overlap: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();

    const notifications = computed(() => {
      return proxy.$notifications.state;
    });

    const removeNotification = (timestamp) => {
      proxy.$notifications.removeNotification(timestamp);
    };

    watch(
      () => props.overlap,
      (newVal) => {
        proxy.$notifications.settings.overlap = newVal;
      },
      { immediate: true }
    );

    return {
      notifications,
      removeNotification,
    };
  },
});
</script>

<style lang="scss">
.notifications {
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }

  .list-item {
    display: inline-block;
    margin-right: 10px;
  }

  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }

  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
</style>
