<template>
  <Card>
    <div>
      <div class="row">
        <div class="col-5" v-if="$slots.header">
          <slot name="header"></slot>
        </div>
        <div class="col-7" v-if="$slots.content">
          <slot name="content"></slot>
        </div>
      </div>
      <div v-if="$slots.footer">
        <hr />
        <slot name="footer"></slot>
      </div>
    </div>
  </Card>
</template>

<script>
import { defineComponent } from 'vue';
import Card from './Card.vue';

export default defineComponent({
  name: 'StatsCard',
  components: {
    Card,
  },
});
</script>

<style></style>
