<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">
        <th v-for="column in columns" :key="column">{{ column }}</th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index">
      <slot :row="item">
        <template v-for="(column, columnIndex) in columns">
          <td :key="columnIndex" v-if="hasValue(item, column)">
            {{ itemValue(item, column) }}
          </td>
        </template>
      </slot>
    </tr>
    </tbody>
  </table>
</template>

<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'BaseTable',
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    type: {
      type: String, // striped | hover
      default: '',
      description: 'Whether table is striped or hover type',
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes',
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes',
    },
  },
  setup(props) {
    const tableClass = computed(() => {
      return props.type ? `table-${props.type}` : '';
    });

    const hasValue = (item, column) => {
      return item[column.toLowerCase()] !== undefined;
    };

    const itemValue = (item, column) => {
      return item[column.toLowerCase()];
    };

    return {
      tableClass,
      hasValue,
      itemValue,
    };
  },
});
</script>

<style></style>
