<template>
  <div
    class="form-group"
    :class="{
      'input-group': hasIcon,
      'input-group-focus': focused,
    }"
  >
    <slot name="label">
      <label v-if="label" class="control-label">
        {{ label }}
      </label>
    </slot>
    <slot name="addonLeft">
      <span v-if="addonLeftIcon" class="input-group-prepend">
        <div class="input-group-text">
          <i :class="addonLeftIcon"></i>
        </div>
      </span>
    </slot>
    <slot>
      <input
        :value="value"
        v-bind="$attrs"
        v-on="listeners"
        class="form-control"
        aria-describedby="addon-right addon-left"
      />
    </slot>
    <slot name="addonRight">
      <span v-if="addonRightIcon" class="input-group-append">
        <div class="input-group-text">
          <i :class="addonRightIcon"></i>
        </div>
      </span>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  name: 'BaseInput',
  props: {
    label: {
      type: String,
      description: 'Input label',
    },
    value: {
      type: [String, Number],
      description: 'Input value',
    },
    addonRightIcon: {
      type: String,
      description: 'Input icon on the right',
    },
    addonLeftIcon: {
      type: String,
      description: 'Input icon on the left',
    },
  },
  emits: ['input'],
  setup(props, { emit, attrs, slots }) {
    const focused = ref(false);

    const hasIcon = computed(() => {
      return (
        slots.addonRight !== undefined ||
        slots.addonLeft !== undefined ||
        props.addonRightIcon !== undefined ||
        props.addonLeftIcon !== undefined
      );
    });

    const listeners = computed(() => ({
      ...attrs,
      input: onInput,
      blur: onBlur,
      focus: onFocus,
    }));

    const onInput = (evt) => {
      emit('input', evt.target.value);
    };

    const onFocus = () => {
      focused.value = true;
    };

    const onBlur = () => {
      focused.value = false;
    };

    return {
      focused,
      hasIcon,
      listeners,
    };
  },
});
</script>

<style></style>
