<template>
  <button
    class="navbar-toggler"
    type="button"
    :data-toggle="collapse"
    :data-target="`#${target}`"
    :aria-controls="target"
    :aria-expanded="toggled"
    aria-label="Toggle navigation"
    @click="$emit('toggle', !toggled)"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavbarToggler',
  props: {
    target: {
      type: [String, Number],
      required: true,
      description: "Button target element",
    },
    toggled: {
      type: Boolean,
      default: false,
      description: "Whether button is toggled",
    },
  },
  emits: ['toggle'],
});
</script>

<style></style>
