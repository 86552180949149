import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";

// configure router
const router = createRouter({
  history: createWebHistory("/vue/"),
  routes,
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { left: 0, top: 0 };
    }
  },
});

export default router;
