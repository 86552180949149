<template>
  <nav
    class="navbar navbar-expand-lg navbar-absolute"
    :class="{ 'bg-white': showMenu, 'navbar-transparent': !showMenu }"
  >
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div
          class="navbar-toggle d-inline"
          :class="{ toggled: $sidebar.showSidebar }"
        >
          <button
            type="button"
            class="navbar-toggler"
            aria-label="Navbar toggle button"
            @click="toggleSidebar"
          >
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <a class="navbar-brand" href="#pablo">{{ routeName }}</a>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        @click="toggleMenu"
        aria-controls="navigation"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <transition name="collapse">
        <div v-show="showMenu" class="collapse navbar-collapse">
          <ul class="navbar-nav" :class="isRTL ? 'mr-auto' : 'ml-auto'">
            <!--            <div-->
            <!--              class="search-bar input-group"-->
            <!--              @click="searchModalVisible = true"-->
            <!--            >-->
            <!--              <button class="btn btn-link" id="search-button">-->
            <!--                <i class="tim-icons icon-zoom-split"></i>-->
            <!--              </button>-->
            <!--            </div>-->
            <!--            <Modal-->
            <!--              v-model:show="searchModalVisible"-->
            <!--              class="modal-search"-->
            <!--              id="searchModal"-->
            <!--              :centered="false"-->
            <!--              :show-close="true"-->
            <!--            >-->
            <!--              <input-->
            <!--                slot="header"-->
            <!--                v-model="searchQuery"-->
            <!--                type="text"-->
            <!--                class="form-control"-->
            <!--                placeholder="SEARCH"-->
            <!--              />-->
            <!--            </Modal>-->
            <!--            <base-dropdown-->
            <!--              icon="mdi mdi-chat"-->
            <!--              tag="li"-->
            <!--              :menu-on-right="!isRTL"-->
            <!--              title-tag="a"-->
            <!--              class="nav-item"-->
            <!--            >-->
            <!--              <a-->
            <!--                slot="title"-->
            <!--                href="#"-->
            <!--                class="dropdown-toggle nav-link"-->
            <!--                aria-expanded="true"-->
            <!--              >-->
            <!--                <div class="notification d-none d-lg-block d-xl-block"></div>-->
            <!--                <i class="tim-icons icon-sound-wave"></i>-->
            <!--                <p class="d-lg-none">New Notifications</p>-->
            <!--              </a>-->
            <!--              <li class="nav-link">-->
            <!--                <a href="#" class="nav-item dropdown-item"-->
            <!--                  >Mike John responded to your email</a-->
            <!--                >-->
            <!--              </li>-->
            <!--              <li class="nav-link">-->
            <!--                <a href="#" class="nav-item dropdown-item"-->
            <!--                  >You have 5 more tasks</a-->
            <!--                >-->
            <!--              </li>-->
            <!--              <li class="nav-link">-->
            <!--                <a href="#" class="nav-item dropdown-item"-->
            <!--                  >Your friend Michael is in town</a-->
            <!--                >-->
            <!--              </li>-->
            <!--              <li class="nav-link">-->
            <!--                <a href="#" class="nav-item dropdown-item"-->
            <!--                  >Another notification</a-->
            <!--                >-->
            <!--              </li>-->
            <!--              <li class="nav-link">-->
            <!--                <a href="#" class="nav-item dropdown-item">Another one</a>-->
            <!--              </li>-->
            <!--            </base-dropdown>-->
            <base-dropdown
              icon="mdi mdi-account"
              tag="li"
              :menu-on-right="!isRTL"
              title-tag="a"
              class="nav-item"
              menu-classes="dropdown-navbar"
            >
              <div class="dropdown-divider"></div>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item" @click="handleLogOut"
                  >Log out</a
                >
              </li>
            </base-dropdown>
          </ul>
        </div>
      </transition>
    </div>
  </nav>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  components: {
    Modal,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    handleLogOut() {
      // TODO dorobit server logout
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push("/");
    },
  },
};
</script>

<style>
.collapse-enter-active,
.collapse-leave-active {
  transition: max-height 0.5s ease;
}

.collapse-enter-from,
.collapse-leave-to {
  max-height: 0;
}
</style>
