<template>
  <li :class="['nav-item', { active: isActive() }]">
    <component
      :is="tag"
      class="nav-link"
      v-bind="$attrs"
      @click="hideSidebar"
      :to="prependHomeRoute($attrs.to)"
    >
      <slot>
        <i v-if="icon" :class="icon"></i>
        <p>{{ name }}</p>
      </slot>
    </component>
  </li>
</template>

<script>
import { defineComponent, inject, onMounted, onBeforeUnmount } from "vue";

export default defineComponent({
  name: "SidebarLink",
  inheritAttrs: false,
  props: {
    name: String,
    icon: String,
    tag: {
      type: String,
      default: "router-link",
    },
  },
  setup(props, { attrs }) {
    const autoClose = inject("autoClose", true);
    const addLink = inject("addLink", () => {});
    const removeLink = inject("removeLink", () => {});
    const sidebar = inject("$sidebar");

    const hideSidebar = () => {
      if (autoClose && sidebar) {
        sidebar.displaySidebar(false);
      }
    };

    const isActive = () => {
      return attrs.class && attrs.class.includes("active");
    };

    const prependHomeRoute = (route) => {
      if (typeof route === "string" && !route.startsWith("/home")) {
        return `/home${route}`;
      }
      return route;
    };

    onMounted(() => {
      if (addLink) {
        addLink();
      }
    });

    onBeforeUnmount(() => {
      if (removeLink) {
        removeLink();
      }
    });

    return {
      hideSidebar,
      isActive,
      prependHomeRoute,
    };
  },
});
</script>

<style>
.mdi {
  font-size: 30px !important;
}
.nav-item {
  list-style: none;
}

.nav-link {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: #47a76a; /* Slightly lighter green for hover */
}

.nav-link.active {
  background-color: #1b563a !important; /* Darker green for active link */
  color: white;
}

.nav-link i {
  margin-right: 10px;
}
</style>
