<template>
  <div
    @click="tryClose"
    data-notify="container"
    class="alert open"
    :class="[
      { 'alert-with-icon': icon },
      verticalAlign,
      horizontalAlign,
      alertType,
    ]"
    role="alert"
    :style="customPosition"
    data-notify-position="top-center"
  >
    <button
      v-if="showClose"
      type="button"
      aria-hidden="true"
      class="close col-xs-1"
      data-notify="dismiss"
      @click="close"
    >
      <i class="tim-icons icon-simple-remove"></i>
    </button>

    <span v-if="icon" data-notify="icon" :class="['alert-icon', icon]"></span>
    <div data-notify="message">
      <div v-if="title" class="title">
        <b>{{ title }}<br /></b>
      </div>
      <div v-if="message" v-html="message"></div>
      <ContentRender v-if="!message && component" :component="component" />
    </div>
  </div>
</template>

<script>
import { defineComponent, h, onMounted, ref, computed } from 'vue';

export default defineComponent({
  name: 'Notification',
  components: {
    ContentRender: {
      props: ['component'],
      setup(props) {
        return () => h(props.component);
      },
    },
  },
  props: {
    message: String,
    title: String,
    icon: String,
    verticalAlign: {
      type: String,
      default: 'top',
      validator: (value) => ['top', 'bottom'].includes(value),
    },
    horizontalAlign: {
      type: String,
      default: 'right',
      validator: (value) => ['left', 'center', 'right'].includes(value),
    },
    type: {
      type: String,
      default: 'info',
      validator: (value) =>
        ['info', 'primary', 'danger', 'warning', 'success'].includes(value),
    },
    timeout: {
      type: Number,
      default: 5000,
      validator: (value) => value >= 0,
    },
    timestamp: {
      type: Date,
      default: () => new Date(),
    },
    component: {
      type: [Object, Function],
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    closeOnClick: {
      type: Boolean,
      default: true,
    },
    clickHandler: Function,
  },
  setup(props, { emit }) {
    const elmHeight = ref(0);

    const alertType = computed(() => `alert-${props.type}`);

    const customPosition = computed(() => {
      const initialMargin = 20;
      const alertHeight = elmHeight.value + 10;
      let sameAlertsCount = this.$notifications.state.filter((alert) => {
        return (
          alert.horizontalAlign === props.horizontalAlign &&
          alert.verticalAlign === props.verticalAlign &&
          alert.timestamp <= props.timestamp
        );
      }).length;

      if (this.$notifications.settings.overlap) {
        sameAlertsCount = 1;
      }

      const pixels = (sameAlertsCount - 1) * alertHeight + initialMargin;
      const styles = {};

      if (props.verticalAlign === 'top') {
        styles.top = `${pixels}px`;
      } else {
        styles.bottom = `${pixels}px`;
      }

      return styles;
    });

    const close = () => {
      emit('close', props.timestamp);
    };

    const tryClose = (evt) => {
      if (props.clickHandler) {
        props.clickHandler(evt, this);
      }
      if (props.closeOnClick) {
        close();
      }
    };

    onMounted(() => {
      elmHeight.value = this.$el.clientHeight;
      if (props.timeout) {
        setTimeout(close, props.timeout);
      }
    });

    return {
      elmHeight,
      alertType,
      customPosition,
      close,
      tryClose,
    };
  },
});
</script>

<style lang="scss">
.notifications .alert {
  position: fixed;
  z-index: 10000;

  &[data-notify="container"] {
    width: 480px;
    cursor: pointer;
  }

  &.center {
    left: 0px;
    right: 0px;
    margin: 0 auto;
  }
  &.left {
    left: 20px;
  }
  &.right {
    right: 20px;
  }
}
</style>
