import { createApp } from "vue";
import App from "./App.vue";
import router from "./router"; // Updated router import
import "@mdi/font/css/materialdesignicons.min.css";
import "element-plus/theme-chalk/dark/css-vars.css";
import "element-plus/dist/index.css";
import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n";
import ElementPlus from "element-plus";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"; // Import the styles

const app = createApp(App);

app.use(Toast, {
  position: "top-right",
  duration: 1500,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = sessionStorage.getItem("token"); // Check if token exists to determine authentication

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: "Login" });
    } else {
      next(); // Proceed to the route
    }
  } else {
    next();
  }
});

app.use(BlackDashboard);
app.use(router);
app.use(i18n);
app.use(ElementPlus);
app.mount("#app");
