<template>
  <button
    type="button"
    class="navbar-toggler"
    data-toggle="collapse"
    @click="handleClick"
    :data-target="`#${target}`"
    :aria-controls="target"
    :aria-expanded="expanded"
    aria-label="Toggle navigation"
  >
    <span></span>
    <span></span>
  </button>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'CloseButton',
  props: {
    target: {
      type: [String, Number],
      required: true,
      description: 'Close button target element',
    },
    expanded: {
      type: Boolean,
      default: false,
      description: 'Whether button is expanded (aria-expanded attribute)',
    },
  },
  emits: ['click'],
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    },
  },
});
</script>

<style></style>
