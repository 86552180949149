<template>
  <component
    :is="tag"
    class="dropdown"
    :class="{ show: isOpen }"
    @click="toggleDropDown"
    v-click-outside="closeDropDown"
  >
    <slot name="title-container" :is-open="isOpen">
      <component
        :is="titleTag"
        class="dropdown-toggle btn-rotate"
        :class="titleClasses"
        :aria-expanded="isOpen"
        :aria-label="title || ariaLabel"
        data-toggle="dropdown"
      >
        <slot name="title" :is-open="isOpen">
          <i :class="icon"></i>
          {{ title }}
        </slot>
      </component>
    </slot>
    <ul
      class="dropdown-menu"
      :class="[
        { show: isOpen },
        { 'dropdown-menu-right': menuOnRight },
        menuClasses,
      ]"
    >
      <slot></slot>
    </ul>
  </component>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'BaseDropdown',
  props: {
    tag: {
      type: String,
      default: 'div',
      description: 'Dropdown HTML tag (e.g., div, ul, etc.)',
    },
    titleTag: {
      type: String,
      default: 'button',
      description: 'Dropdown title (toggle) HTML tag',
    },
    title: {
      type: String,
      description: 'Dropdown title',
    },
    icon: {
      type: String,
      description: 'Dropdown icon',
    },
    titleClasses: {
      type: [String, Object, Array],
      description: 'Title CSS classes',
    },
    menuClasses: {
      type: [String, Object],
      description: 'Menu CSS classes',
    },
    menuOnRight: {
      type: Boolean,
      description: 'Whether the menu should appear on the right',
    },
    ariaLabel: String,
  },
  emits: ['change'],
  setup(props, { emit }) {
    const isOpen = ref(false);

    const toggleDropDown = () => {
      isOpen.value = !isOpen.value;
      emit('change', isOpen.value);
    };

    const closeDropDown = () => {
      isOpen.value = false;
      emit('change', false);
    };

    return {
      isOpen,
      toggleDropDown,
      closeDropDown,
    };
  },
});
</script>
