<template>
  <div class="form-check" :class="[{ disabled: disabled }, inlineClass]">
    <label :for="cbId" class="form-check-label">
      <input
        :id="cbId"
        class="form-check-input"
        type="checkbox"
        :disabled="disabled"
        v-model="model"
      />
      <span class="form-check-sign"></span>
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
  name: 'BaseCheckbox',
  props: {
    checked: {
      type: [Array, Boolean],
      required: true,
      description: 'Whether checkbox is checked',
    },
    disabled: {
      type: Boolean,
      default: false,
      description: 'Whether checkbox is disabled',
    },
    inline: {
      type: Boolean,
      default: false,
      description: 'Whether checkbox should be inline with other checkboxes',
    },
  },
  emits: ['update:checked'],
  setup(props, { emit }) {
    const cbId = ref(Math.random().toString(16).slice(2));
    const touched = ref(false);

    const model = computed({
      get() {
        return props.checked;
      },
      set(check) {
        if (!touched.value) {
          touched.value = true;
        }
        emit('update:checked', check);
      },
    });

    const inlineClass = computed(() => (props.inline ? 'form-check-inline' : ''));

    return {
      cbId,
      model,
      inlineClass,
    };
  },
});
</script>
