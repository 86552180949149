<template>
  <nav
    class="navbar"
    :class="[
      { 'navbar-expand-lg': expand },
      { [`navbar-${effect}`]: effect },
      { 'navbar-transparent': transparent },
      { [`bg-${type}`]: type },
      { rounded: round },
    ]"
  >
    <div class="container">
      <slot name="container-pre"></slot>
      <slot name="brand">
        <a class="navbar-brand" href="#" @click.prevent="onTitleClick">
          {{ title }}
        </a>
      </slot>
      <NavbarToggleButton
        :toggled="toggled"
        :target="contentId"
        @click.stop="toggled = !toggled"
      >
      </NavbarToggleButton>

      <slot name="container-after"></slot>

      <div
        class="collapse navbar-collapse"
        :class="{ show: toggled }"
        :id="contentId"
        v-click-outside="closeMenu"
      >
        <div class="navbar-collapse-header">
          <slot name="content-header" :close-menu="closeMenu"></slot>
        </div>
        <slot :close-menu="closeMenu"></slot>
      </div>
    </div>
  </nav>
</template>

<script>
import { defineComponent, ref } from 'vue';
import NavbarToggleButton from './NavbarToggleButton';

export default defineComponent({
  name: 'BaseNav',
  components: {
    NavbarToggleButton,
  },
  props: {
    type: {
      type: String,
      default: 'primary',
      description: 'Navbar type (e.g., default, primary, etc.)',
    },
    title: {
      type: String,
      default: '',
      description: 'Title of the navbar',
    },
    contentId: {
      type: [String, Number],
      default: () => Math.random().toString(36).substr(2, 9),
      description: "Explicit ID for the menu. By default, it's a generated random number",
    },
    effect: {
      type: String,
      default: 'dark',
      description: 'Effect of the navbar (light|dark)',
    },
    round: {
      type: Boolean,
      default: false,
      description: 'Whether the navbar has rounded corners',
    },
    transparent: {
      type: Boolean,
      default: false,
      description: 'Whether the navbar is transparent',
    },
    expand: {
      type: Boolean,
      default: false,
      description: 'Whether the navbar should contain `navbar-expand-lg` class',
    },
  },
  setup(props, { emit }) {
    const toggled = ref(false);

    const onTitleClick = (evt) => {
      emit('title-click', evt);
    };

    const closeMenu = () => {
      toggled.value = false;
    };

    return {
      toggled,
      onTitleClick,
      closeMenu,
    };
  },
});
</script>

<style></style>
