<template>
  <div
    class="form-check form-check-radio"
    :class="[inlineClass, { disabled: disabled }]"
  >
    <label :for="cbId" class="form-check-label">
      <input
        :id="cbId"
        class="form-check-input"
        type="radio"
        :disabled="disabled"
        :value="name"
        v-model="model"
      />
      <slot></slot>
      <span class="form-check-sign"></span>
    </label>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
  name: 'BaseRadio',
  props: {
    name: {
      type: [String, Number],
      description: 'Radio label',
    },
    disabled: {
      type: Boolean,
      description: 'Whether radio is disabled',
    },
    value: {
      type: [String, Boolean],
      required: true,
      description: 'Radio value',
    },
    inline: {
      type: Boolean,
      description: 'Whether radio is inline',
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const cbId = ref(Math.random().toString(16).slice(2));

    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        emit('update:value', value);
      },
    });

    const inlineClass = computed(() => {
      return props.inline ? 'form-check-inline' : '';
    });

    return {
      cbId,
      model,
      inlineClass,
    };
  },
});
</script>
